import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="About" contentType="profile" firstName="Nathan" lastName="Duff" gender="male" profileImage="/static/images/nate.jpg" />
    <h1>About Me</h1>
    <p>Hello, my name is Nate Duff and I like to make things.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AboutPage
